<script setup lang="ts">
import { useMagicKeys } from '@vueuse/core';
import axios from 'axios'
const colorMode = useColorMode()
const isDark = computed({
    get() {
        return colorMode.value === 'dark'
    },
    set() {
        colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
    }
})

const isOpen = ref(false)
const isSlideOpen = ref(false)
const isDonateOpen = ref(false)
const searchResults = ref([])
const searchQuery = ref('')
const selected = ref(false)

const resetSearch = () => {
    searchQuery.value = ''
    isOpen.value = false
}

// Function to fetch search results from Meilisearch
const fetchResults = async (query) => {
    try {
        const response = await axios.get(`/api/search?q=${query}&hits=10`)
        searchResults.value = response.data.results
    } catch (error) {
        console.error('Error fetching search results:', error)
    }
}

// Watch the searchQuery ref to trigger search on input
watch(searchQuery, (newValue) => {
    if (newValue) {
        fetchResults(newValue)
    } else {
        searchResults.value = []
    }
})

// Debounce the input to avoid too many requests
let debounceTimeout
const onInput = (query) => {
    clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(() => {
        if (query) {
            fetchResults(query)
        }
    }, 300) // Adjust the delay as needed
}

// Magic keys
const keys = useMagicKeys({
    passive: false,
    onEventFired(e) {
        if (e.ctrlKey && e.key === 'k') {
            e.preventDefault()
        }
    }
})
const ctrlK = keys['Ctrl+K']

watch(ctrlK, (v) => {
    if (v) {
        isOpen.value = true
    }
})
</script>

<template>
    <header
        class="flex items-center justify-between p-4 md:px-8 dark:bg-cool-700/20 dark:text-white sticky top-0 z-50 bg-white/20 backdrop-blur-xl">
        <!-- DESKTOP -->
        <div class="hidden lg:flex left-part flex-1 items-center gap-4 justify-start">
            <ULink to="/"
                inactive-class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200">
                Accueil
            </ULink>
            <ULink to="/cards"
                inactive-class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200">
                Cartes
            </ULink>
            <ULink to="https://wankulmarket.sleekplan.app/changelog" target="_blank"
                inactive-class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200">
                Changelog
            </ULink>
            <ULink to="https://wankulmarket.sleekplan.app" target="_blank"
                inactive-class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200">
                Feedback
            </ULink>
            <ULink inactive-class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
                @click="isDonateOpen = true">
                <UIcon class="text-xl" name="i-ph-hand-heart" dynamic />
                Faire un don
            </ULink>
        </div>
        <!-- END DESKTOP -->
        <UButton class="lg:hidden" icon="i-heroicons-bars-3" size="lg" color="white" square variant="ghost"
            @click="isSlideOpen = true" />
        <div
            class="center-title font-yellowtail text-3xl md:text-4xl flex-1 flex justify-center font-bold items-baseline gap-2">
            Wankul Market
        </div>
        <div class="right-part lg:flex-1 flex justify-end items-center md:gap-4">
            <div @click="isOpen = true"
                class="hidden lg:flex items-center justify-between gap-4 p-4 w-60 rounded-full bg-slate-200/25 dark:bg-cool-600/25 dark:text-gray-200">
                <div class="flex gap-4">
                    <UIcon name="i-heroicons-magnifying-glass text-2xl" />
                    <span>Rechercher...</span>
                </div>
                <div class="flex items-center gap-1">
                    <UKbd class="bg-slate-200/25 dark:bg-cool-600/25 ring-slate-200/25 dark:ring-cool-600/25">
                        Ctrl
                    </UKbd>
                    <UKbd class="bg-slate-200/25 dark:bg-cool-600/25 ring-slate-200/25 dark:ring-cool-600/25">
                        K
                    </UKbd>
                </div>
            </div>
            <UButton class="lg:hidden text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
                icon="i-heroicons-magnifying-glass" size="lg" color="white" square variant="ghost"
                @click="isOpen = true" />
            <ClientOnly>
                <UButton class="h-fit" :icon="isDark ? 'i-heroicons-moon-20-solid' : 'i-heroicons-sun-20-solid'"
                    color="gray" variant="ghost" aria-label="Theme" @click="isDark = !isDark" />
                <template #fallback>
                    <div class="w-8 h-8" />
                </template>
            </ClientOnly>
        </div>
        <!-- MOBILE -->
        <USlideover v-model="isSlideOpen">
            <div class="p-4 flex-1">
                <UButton color="gray" variant="ghost" size="sm" icon="i-heroicons-x-mark-20-solid"
                    class="flex sm:hidden absolute end-5 top-5 z-10" square padded @click="isSlideOpen = false" />
                <div class="flex text-lg flex-col left-part flex-1 items-center gap-8 h-full justify-center">
                    <ULink to="/" class="text-xl" @click="isSlideOpen = false"
                        inactive-class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200">
                        Accueil
                    </ULink>
                    <ULink to="/cards" class="text-xl" @click="isSlideOpen = false"
                        inactive-class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200">
                        Cartes
                    </ULink>
                    <ULink to="https://wankulmarket.sleekplan.app/changelog" target="_blank" class="text-xl"
                        @click="isSlideOpen = false"
                        inactive-class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200">
                        Changelog
                    </ULink>
                    <ULink to="https://wankulmarket.sleekplan.app" target="_blank" class="text-xl"
                        @click="isSlideOpen = false"
                        inactive-class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200">
                        Feedback
                    </ULink>
                    <ULink
                        inactive-class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
                        class="text-xl" @click="isDonateOpen = true">
                        <UIcon class="text-xl" name="i-ph-hand-heart" dynamic />
                        Faire un don
                    </ULink>
                </div>
            </div>
        </USlideover>
        <UModal :ui="{ rounded: 'rounded-2xl', base: 'md:max-w-xl' }" v-model="isOpen">
            <UInput class="hidden md:block" color="white" v-model="searchQuery" variant="none"
                placeholder="Rechercher..." icon="i-heroicons-magnifying-glass" @key.native.enter="onInput" />
            <div class="text-center md:hidden block" v-if="searchResults.length <= 0">
                <p>Aucun résultat</p>
            </div>
            <div class="p-4" v-if="searchResults.length > 0">
                <div class="flex flex-col gap-2">
                    <div class="flex flex-col md:flex-row justify-between" v-for="result in searchResults"
                        :key="result.id">
                        <div class="flex gap-2">
                            <UAvatar v-if="result.effigy === 'Laink'" src="/images/laink.png" size="xs" alt="Avatar" />
                            <UAvatar v-if="result.effigy === 'Terracid'" src="/images/terra.png" size="xs"
                                alt="Avatar" />
                            <div class="font-semibold" v-html="result._formatted.title"></div>
                            <div class="text-sm text-neutral-500" v-html="result._formatted.rarity"></div>
                        </div>
                        <div class="flex items-center gap-2 justify-between md:justify-normal">
                            <span class="flex items-center gap-1 text-sm">
                                <UIcon name="i-heroicons-tag" />
                                <span v-html="result._formatted.number"></span>
                            </span>
                            <span class="flex items-center gap-1 text-sm">
                                <UIcon name="i-heroicons-rectangle-stack" />
                                {{ result.dropRate || '0%' }}
                            </span>
                            <ULink :to="`/cards/${result.id}`" active-class="text-primary"
                                inactive-class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
                                @click="resetSearch()">
                                <UIcon name="i-heroicons-arrow-right" />
                            </ULink>
                        </div>
                        <hr class="md:hidden">
                    </div>
                </div>
            </div>
            <UInput class="md:hidden block" color="white" v-model="searchQuery" variant="none"
                placeholder="Rechercher..." icon="i-heroicons-magnifying-glass" @key.native.enter="onInput" />
            <div class="text-center hidden md:block" v-if="searchResults.length <= 0">
                <p>Aucun résultat</p>
            </div>
        </UModal>
        <UModal v-model="isDonateOpen">
            <div class="flex flex-col gap-4 p-4">
                <div class="text-2xl uppercase font-bold">Faire un don</div>
                <div class="font-semibold">Vous êtes sur le point de faire un don pour soutenir le développement de
                    WankulMarket.</div>
                <div class="italic">L'argent des dons sera utiliser pour contribuer aux coût des serveurs et, dans la
                    mesure du
                    possible,
                    organiser des concours pour remporter des boosters Wankul.</div>
                <div class="flex justify-between">
                    <UCheckbox v-model="selected" color="blue" name="notifications"
                        label="Je comprends et souhaite faire un don" />
                    <form v-if="selected" action="https://www.paypal.com/donate" method="post" target="_top">
                        <input type="hidden" name="hosted_button_id" value="VJMB25Z7ZAUVY" />
                        <input type="image" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_donate_SM.gif"
                            border="0" name="submit" title="PayPal - The safer, easier way to pay online!"
                            alt="Bouton Faites un don avec PayPal" />
                        <img alt="Paypal image" border="0" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" width="1"
                            height="1" />
                    </form>
                </div>
            </div>
        </UModal>
    </header>
</template>

<script lang="ts">
export default {
    methods: {
        formatPrice(price) {
            return parseFloat(price).toFixed(2)
        }
    }
}
</script>
